import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '../services/image.service';
import { Book } from '../models/book.model';
import { DefaultProjectState } from '../store/default-project.state';
import { UserLocationState } from '../store/user-location.state';
import { NetworkService } from '../services/network.service';

enum CompressedImageSize {
  Xxs = '60x80',
  Xs = '120x160',
  S = '240x320',
  M = '480x800',
  L = '720x1280',
  Xl = '768x1280',
  Xxl = '1080x1920',
}

@Pipe({ name: 'compressedImage', standalone: true })
export class CompressedImagePipe implements PipeTransform {
  constructor(
    private imageService: ImageService,
    private projectState: DefaultProjectState,
    private userLocationState: UserLocationState,
    private networkService: NetworkService,
  ) {}

  transform(value: Book): string {
    return this.imageService.getBookImage({
      uuid: value.uuid,
      version: value.version,
      projectId: this.projectState.defaultProject$.value.id,
      countryCode: this.userLocationState.userLocation$.value.countryCode,
      pathType: 'full_path',
      imagePath: value.coverImagePath,
      size: this.networkService.connectionType$.value === 'wifi' ? CompressedImageSize.S : CompressedImageSize.Xs,
    });
  }
}
